.header {


  &__pc {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    padding: 10px;
    background: url(../img/stmp_bg2.png) #1a1a1a;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include tablet {
      display: none;
    }
  }

  &__line{
    position: fixed;
    top: 44px;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: 100;
  }

  &__mobile {

    display: none;

    @include tablet {
      display: block;
    }
  }

  &__logo {
    display: block;
    width: 200px;
    height: 30px;
    background-color: red;
  }

  &__right {
    display: flex;
    gap: 15px;
    align-items: center;
  }

  &__searh {
    width: 20px;
    margin-top: 2px;
  }

  &__svg {
    fill: url(#linear-gradient);
    width: 100%;
  }

  &__profile {
    width: 20px;
    margin-top: 5px;

    svg {
      fill: url(#linear-gradient);
      width: 100%;
    }
  }
}

.mobile{

  &__top {
    background: url(../img/stmp_bg2.png) #1a1a1a;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 7px;
    height: 44px;
    z-index: 100;
  }

  &__bottom{
    background-color: #000;
    position: fixed;
    top: 49px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 7px;
    height: 44px;
    z-index: 100;
  }

  &__btns{
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__join{
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    background: linear-gradient(153.43deg, #fcf45f 10%, #fead25 93.33%);
    height: 31px;
    border-radius: 5px;
    padding: 7px 16px;
    color: #000;
  }

  &__login{

    span{
      background: linear-gradient(180deg, #fcf45f 10%, #fead25 93.33%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 13px;
    }

    display: flex;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(#0b1019, #0b1019) padding-box, linear-gradient(153.43deg, #fcf45f 10%, #fead25 93.33%) border-box;
    border-radius: 5px;
    border: 1px solid transparent;
    padding: 7px 16px;
    height: 31px;
  }
}

.nav-mobile{
  width: 100%;

  &__list{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__link{
    background: linear-gradient(180deg, #fcf45f 10%, #fead25 93.33%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
  }

  &__svg{
    fill: url(#linear-gradient-mob);
    width: 100%;
  }
}

.nav {

  &__list {
    display: flex;
    gap: 15px;
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    color: var(--second-color);
  }
}

.menu {
  position: relative;

  &__btn {
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid #b6b8b8;
    padding: 5.5px 10px;
    border-radius: 7px;
  }

  &__img {
    width: 14px;
    height: 14px;
  }
}

.dropdown {
  position: absolute;
  top: 27px;
  right: 0;
  background-color: #313131;
  border: 1px solid #b6b8b8;
  border-radius: 7px;
  padding: 8px 23px;
  display: none;

  &--active {
    display: block;
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover::before {
      list-style-type: disc;
      position: absolute;
      top: -20px;
      left: 10px;
      content: ".";
      color: #FDCF37;
      font-size: 46px;
    }
  }

  &__img {
    width: 25px;
    height: 25px;
  }

  &__span {

    text-transform: uppercase;
    color: var(--second-color);
  }
}
