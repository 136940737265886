@charset "UTF-8";
:root {
  --font-family: "Montserrat", sans-serif;
  --content-width: 1200px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --main-color:#686868;
  --second-color: #fff;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable value-keyword-case */
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/MontserratRegular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/MontserratMedium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/MontserratSemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/MontserratBold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  font-weight: 400;
  color: var(--main-color);
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

h1, h2, h3, h4, h5, h6, p {
  padding: 0;
  margin: 0;
}

.header__pc {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 10px;
  background: url(../img/stmp_bg2.png) #1a1a1a;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1024px) {
  .header__pc {
    display: none;
  }
}
.header__line {
  position: fixed;
  top: 44px;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 100;
}
.header__mobile {
  display: none;
}
@media (max-width: 1024px) {
  .header__mobile {
    display: block;
  }
}
.header__logo {
  display: block;
  width: 200px;
  height: 30px;
  background-color: red;
}
.header__right {
  display: flex;
  gap: 15px;
  align-items: center;
}
.header__searh {
  width: 20px;
  margin-top: 2px;
}
.header__svg {
  fill: url(#linear-gradient);
  width: 100%;
}
.header__profile {
  width: 20px;
  margin-top: 5px;
}
.header__profile svg {
  fill: url(#linear-gradient);
  width: 100%;
}

.mobile__top {
  background: url(../img/stmp_bg2.png) #1a1a1a;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 7px;
  height: 44px;
  z-index: 100;
}
.mobile__bottom {
  background-color: #000;
  position: fixed;
  top: 49px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 7px;
  height: 44px;
  z-index: 100;
}
.mobile__btns {
  display: flex;
  gap: 8px;
  align-items: center;
}
.mobile__join {
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  background: linear-gradient(153.43deg, #fcf45f 10%, #fead25 93.33%);
  height: 31px;
  border-radius: 5px;
  padding: 7px 16px;
  color: #000;
}
.mobile__login {
  display: flex;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(#0b1019, #0b1019) padding-box, linear-gradient(153.43deg, #fcf45f 10%, #fead25 93.33%) border-box;
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 7px 16px;
  height: 31px;
}
.mobile__login span {
  background: linear-gradient(180deg, #fcf45f 10%, #fead25 93.33%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 13px;
}

.nav-mobile {
  width: 100%;
}
.nav-mobile__list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-mobile__link {
  background: linear-gradient(180deg, #fcf45f 10%, #fead25 93.33%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}
.nav-mobile__svg {
  fill: url(#linear-gradient-mob);
  width: 100%;
}

.nav__list {
  display: flex;
  gap: 15px;
}
.nav__link {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  color: var(--second-color);
}

.menu {
  position: relative;
}
.menu__btn {
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid #b6b8b8;
  padding: 5.5px 10px;
  border-radius: 7px;
}
.menu__img {
  width: 14px;
  height: 14px;
}

.dropdown {
  position: absolute;
  top: 27px;
  right: 0;
  background-color: #313131;
  border: 1px solid #b6b8b8;
  border-radius: 7px;
  padding: 8px 23px;
  display: none;
}
.dropdown--active {
  display: block;
}
.dropdown__link {
  display: flex;
  align-items: center;
  gap: 8px;
}
.dropdown__link:hover::before {
  list-style-type: disc;
  position: absolute;
  top: -20px;
  left: 10px;
  content: ".";
  color: #FDCF37;
  font-size: 46px;
}
.dropdown__img {
  width: 25px;
  height: 25px;
}
.dropdown__span {
  text-transform: uppercase;
  color: var(--second-color);
}

.content {
  padding-top: 70px;
  margin-bottom: 40px;
}
@media (max-width: 1024px) {
  .content {
    padding-top: 110px;
  }
}
.content p {
  font-size: 17px;
  color: #686868;
  line-height: 1.66em;
  word-wrap: break-word;
  margin-bottom: 15px;
}
.content h1 {
  max-width: 1200px;
  margin: 0 auto;
  color: #3C3D3C;
  text-align: center;
  font-size: 23px;
  margin-bottom: 25px;
}
.content h2 {
  font-size: 19px;
  color: #4C4C4C;
  word-wrap: break-word;
  line-height: 1.3em;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 25px;
}
.content h2::before {
  content: "";
  width: 25px;
  height: 25px;
  padding: 0 18px;
  background: url(../img/diamond.png) 0 0 no-repeat;
}
.content h3 {
  font-size: 18px;
  color: #4C4C4C;
  word-wrap: break-word;
  line-height: 1.3em;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 15px;
}
.content strong {
  font-weight: 600;
}
.content ul strong {
  line-height: 1.3em;
  font-weight: 600;
}
.content ul {
  margin: 15px 0;
  padding-left: 40px;
  font-size: 17px;
}
.content ul li:not(:last-child) {
  margin-bottom: 10px;
}
.content__img {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin: 35px auto;
}
.content__img img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: 100%;
  display: block;
}
.content__home {
  color: #000;
  text-decoration: none;
  font-size: 13px;
  font-weight: 600;
}
.content__main {
  color: #fa8e07;
  font-size: 13px;
  font-weight: 600;
}
.content__main::before {
  content: "»";
  color: #fa8e07;
  font-weight: 600;
  padding: 0 4px 0 2px;
}
.content__line {
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0;
  height: 5px;
}
@media (max-width: 1024px) {
  .content__line {
    display: none;
  }
}

.footer {
  width: 100%;
  padding-bottom: 50px;
  background: url(../img/stmp_bg2.png) #1a1a1a;
  position: relative;
}
.footer__logo {
  display: block;
  width: 190px;
  height: 30px;
  background-color: red;
}
.footer__top {
  padding-top: 70px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
}
@media (max-width: 1024px) {
  .footer__top {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 768px) {
  .footer__top {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 576px) {
  .footer__top {
    grid-template-columns: repeat(1, 1fr);
  }
}
.footer__bottom {
  color: #fff;
  margin: 30px 40px 20px 40px;
  font-size: 14px;
  text-align: center;
}
.footer__contacts {
  color: #fa8e07;
  font-weight: 600;
  text-decoration: underline;
}
.footer__line {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 5px;
}

.left__item:first-child {
  margin-bottom: 20px;
}
.left__descr {
  color: var(--second-color);
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}
.left__text {
  color: var(--second-color);
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
}

.social {
  margin-top: 15px;
  display: flex;
  gap: 20px;
}
.social__icon {
  height: 25px;
  color: #fff;
}

.gaming__item:first-child {
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 17px;
  color: #fff;
}
.gaming__item:not(:first-child):not(:last-child) {
  margin-bottom: 20px;
}
.gaming__images {
  width: 185px;
}

.partneres__item:first-child {
  font-weight: 600;
  font-size: 17px;
  color: #fff;
  margin-bottom: 25px;
}
.partneres__item a {
  font-weight: 600;
  font-size: 13px;
  color: #fff;
}
.partneres__item:not(:first-child):not(:last-child) {
  margin-bottom: 15px;
}

.links__item:first-child {
  font-weight: 600;
  font-size: 17px;
  color: #fff;
  margin-bottom: 25px;
}
.links__item a {
  font-weight: 600;
  font-size: 13px;
  color: #fff;
}
.links__item:not(:first-child):not(:last-child) {
  margin-bottom: 15px;
}

.right__item:first-child {
  font-weight: 600;
  font-size: 17px;
  color: #fff;
  margin-bottom: 25px;
}
.right__descr {
  margin-top: 12px;
  font-size: 11px;
  line-height: 136%;
  font-weight: 600;
  color: #fff;
}

.copyright {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #000;
  padding: 15px 0;
  text-align: center;
  font-size: 10px;
  color: #fff;
}