// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Montserrat", sans-serif;
  --content-width: 1200px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));


  //colors
  --main-color:#686868;
  --second-color: #fff;

}
