.footer {
  width: 100%;
  padding-bottom: 50px;
  background: url(../img/stmp_bg2.png) #1a1a1a;
  position: relative;

  &__logo {
    display: block;
    width: 190px;
    height: 30px;
    background-color: red;
  }

  &__top {
    padding-top: 70px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 30px;

    @include tablet{
      grid-template-columns: repeat(3,1fr)
    }

    @include small-tablet{
      grid-template-columns: repeat(2,1fr)
    }

    @include mobile{
      grid-template-columns: repeat(1,1fr)
    }
  }

  &__bottom {
    color: #fff;
    margin: 30px 40px 20px 40px;
    font-size: 14px;
    text-align: center;
  }

  &__contacts {
    color: #fa8e07;
    font-weight: 600;
    text-decoration: underline;
  }

  &__line{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 5px;
  }
}

.left {

  &__item:first-child {
    margin-bottom: 20px;
  }

  &__descr {
    color: var(--second-color);
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  &__text {
    color: var(--second-color);
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
  }
}

.social {
  margin-top: 15px;
  display: flex;
  gap: 20px;

  &__icon {
    height: 25px;
    color: #fff;
  }
}

.gaming {

  &__item:first-child {
    margin-bottom: 25px;
    font-weight: 600;
    font-size: 17px;
    color: #fff;
  }

  &__item:not(:first-child):not(:last-child) {
    margin-bottom: 20px;
  }

  &__images {
    width: 185px;
  }
}

.partneres {

  &__item:first-child {
    font-weight: 600;
    font-size: 17px;
    color: #fff;
    margin-bottom: 25px;
  }

  &__item a {
    font-weight: 600;
    font-size: 13px;
    color: #fff;
  }

  &__item:not(:first-child):not(:last-child) {
    margin-bottom: 15px;
  }
}

.links {

  &__item:first-child {
    font-weight: 600;
    font-size: 17px;
    color: #fff;
    margin-bottom: 25px;
  }

  &__item a {
    font-weight: 600;
    font-size: 13px;
    color: #fff;
  }

  &__item:not(:first-child):not(:last-child) {
    margin-bottom: 15px;
  }
}

.right {

  &__item:first-child {
    font-weight: 600;
    font-size: 17px;
    color: #fff;
    margin-bottom: 25px;
  }

  &__descr {
    margin-top: 12px;
    font-size: 11px;
    line-height: 136%;
    font-weight: 600;
    color: #fff;
  }
}

.copyright {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #000;
  padding: 15px 0;
  text-align: center;
  font-size: 10px;
  color: #fff;
}
