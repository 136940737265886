.content {

  padding-top: 70px;
  margin-bottom: 40px;

  @include tablet{
    padding-top: 110px;
  }

  p {
    font-size: 17px;
    color: #686868;
    line-height: 1.66em;
    word-wrap: break-word;
    margin-bottom: 15px;
  }

  h1 {
    max-width: 1200px;
    margin: 0 auto;
    color: #3C3D3C;
    text-align: center;
    font-size: 23px;
    margin-bottom: 25px;
  }

  h2 {
    font-size: 19px;
    color: #4C4C4C;
    word-wrap: break-word;
    line-height: 1.3em;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 25px;
  }

  h2::before {
    content: "";
    width: 25px;
    height: 25px;
    padding: 0 18px;
    background: url(../img/diamond.png) 0 0 no-repeat;
  }

  h3 {
    font-size: 18px;
    color: #4C4C4C;
    word-wrap: break-word;
    line-height: 1.3em;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 15px;
  }

  strong {
    font-weight: 600;
  }

  ul strong {
    line-height: 1.3em;
    font-weight: 600;
  }

  ul {
    margin: 15px 0;
    padding-left: 40px;
    font-size: 17px;
  }

  ul li:not(:last-child) {
    margin-bottom: 10px;
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin: 35px auto;

    img {
      max-width: 100%;
      max-height: 100%;
      height: auto;
      width: 100%;
      display: block;
    }
  }

  &__home {
    color: #000;
    text-decoration: none;
    font-size: 13px;
    font-weight: 600;
  }

  &__main {
    color: #fa8e07;
    font-size: 13px;
    font-weight: 600;

    &::before {
      content: "»";
      color: #fa8e07;
      font-weight: 600;
      padding: 0 4px 0 2px;
    }
  }

  &__line {
    position: absolute;
    width: 100%;
    top: 50px;
    left: 0;
    height: 5px;

    @include tablet{
      display: none;
    }
  }
}
